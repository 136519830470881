import React, { FC, useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { LawyerConnection } from '@law-connect/types';
import style from './modal.module.less';
import placeholderImage from '../../../assets/profile-no-img.png';
import CrossCircleIcon from '../../../assets/icons/cross-circle.svg?react';
import UserIcon from '../../../assets/icons/user.svg?react';
import BriefcaseIcon from '../../../assets/icons/briefcase.svg?react';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  connection: LawyerConnection;
}

export const LawyerIntroModal: FC<Props> = ({
  isOpen,
  closeModal,
  connection,
}) => {
  const { t } = useTranslation();

  const [showingFirmBio, setShowingFirmBio] = useState(false);

  useEffect(() => {
    // stop scrolling when modal is open
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  if (!connection) {
    return null;
  }

  return (
    <>
      {isOpen && (
        <div className={style.fullScreenModal}>
          <div className={style.wrapper}>
            <div className={style.headerModal}>
              <div className={style.lawyerName}>
                {connection.lawyer.firstName}
                {connection.lawyer.firstName && connection.lawyer.lastName
                  ? ' '
                  : ''}
                {connection.lawyer.lastName}
              </div>
            </div>
            <div className={style.closeIconContainer} onClick={closeModal}>
              <CrossCircleIcon className={style.closeIcon} />
            </div>
            <div className={style.bodyContainer}>
              <div className={style.lawyerInfoCard}>
                <img
                  src={connection.lawyer.image}
                  alt='lawyer avatar'
                  className={style.lawyerImage}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = placeholderImage;
                  }}
                />
                <div className={style.lawyerDetails}>
                  <div className={style.lawyerName}>
                    {connection.lawyer.firstName}
                    {connection.lawyer.firstName && connection.lawyer.lastName
                      ? ' '
                      : ''}
                    {connection.lawyer.lastName}
                  </div>
                  {connection.lawyer.firm.name ? (
                    <div className={style.firmName}>
                      {connection.lawyer.firm.name}
                    </div>
                  ) : null}
                  {/* {connection.lawyer.email ? (
                    <a
                      href={`mailto:${connection.lawyer.email}`}
                      className={style.lawyerEmail}
                    >
                      {connection.lawyer.email}
                    </a>
                  ) : null} */}
                  {connection?.lawyer?.firm.website && (
                    <a
                      href={connection.lawyer.firm.website}
                      className={style.lawyerEmail}
                    >
                      {connection.lawyer.firm.website}
                    </a>
                  )}
                  {connection.lawyer.phoneNumber ? (
                    <div className={style.phoneContainer}>
                      <span>Phone:&nbsp;</span>
                      <a
                        href={`tel:${connection.lawyer.phoneNumber}`}
                        className={style.lawyerEmail}
                      >
                        {connection.lawyer.phoneNumber}
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className={style.messageContainer}>
                <div className={style.toggleContainer}>
                  <div className={style.toggleWrapper}>
                    <div
                      className={`${style.toggleButton} ${
                        showingFirmBio ? '' : style.active
                      }`}
                      onClick={() => setShowingFirmBio(false)}
                    >
                      <UserIcon className={style.toggleIcon} />
                      <span className={style.toggleText}>
                        {t('connect.lawyer-bio')}
                      </span>
                    </div>
                    <div
                      className={`${style.toggleButton} ${
                        showingFirmBio ? style.active : ''
                      }`}
                      onClick={() => setShowingFirmBio(true)}
                    >
                      <BriefcaseIcon className={style.toggleIcon} />
                      <span className={style.toggleText}>
                        {t('connect.firm-bio')}
                      </span>
                    </div>
                    <div
                      className={`${style.toggleActiveBG} ${
                        showingFirmBio ? style.right : ''
                      }`}
                    />
                  </div>
                </div>
                <div className={style.markdownWrapper}>
                  <div
                    className={`${style.hideMessageWrapper}  ${
                      showingFirmBio ? style.slideRight : ''
                    }`}
                  >
                    <Markdown className={`${style.message}`}>
                      {connection.lawyer.presentation}
                    </Markdown>
                    <Markdown className={`${style.message}`}>
                      {connection.lawyer.firm.description}
                    </Markdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
