import React, {
  CSSProperties,
  FC,
  SVGProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import style from './style.module.less';

import ArrowRightIcon from '../../../assets/icons/arrow-right.svg?react';
// home carousel step assets
import ChatIcon from '../../../assets/icons/chat.svg?react';
import ShieldTickIcon from '../../../assets/icons/shield-tick.svg?react';
import RandomIcon from '../../../assets/icons/random.svg?react';
import step1Image from '../../../assets/home/home-feature-1.jpg';
import step2Image from '../../../assets/home/home-feature-2.jpg';
import step3Image from '../../../assets/home/home-feature-3.jpg';
import step1Video from '../../../assets/home/home-video-1.mp4';
import step2Video from '../../../assets/home/home-video-2.mp4';
import step3Video from '../../../assets/home/home-video-3.mp4';
import { CHAT_EMAIL } from '../../../constants';
import { useScreenSize, WidthType } from '../../../hooks/use-is-mobile';
import { MobileStepsCarousel } from './mobile';
import { CarouselHeader } from './header';
import { PercentageSpinner } from '../../../components/percentage-spinner';

export interface Steps {
  name: string;
  Icon: FC<SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
  image: string;
  video: string;
}

const PROGRESS_TIME = 12000;
const ANIMATION_DELAY = 500;

export const StepsCarouselComponent: FC = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [progressRate, setProgressRate] = useState<number>(0); // the percentage of the progress bar
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [videoLooped, setVideoLooped] = useState(false);

  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement>(null);
  const nextVideoRef = useRef<HTMLVideoElement>(null);
  const screenType = useScreenSize();

  const timer = useRef<number | null>(null);
  const carouselSteps: Steps[] = useMemo(
    () => [
      {
        name: t('home.how-it-works.step1.subtitle'),
        Icon: ChatIcon,
        title: t('home.how-it-works.step1.title'),
        description: t('home.how-it-works.step1.description'),
        image: step1Image,
        video: step1Video,
      },
      {
        name: t('home.how-it-works.step2.subtitle'),
        Icon: ShieldTickIcon,
        title: t('home.how-it-works.step2.title'),
        description: t('home.how-it-works.step2.description'),
        image: step2Image,
        video: step2Video,
      },
      {
        name: t('home.how-it-works.step3.subtitle'),
        Icon: RandomIcon,
        title: t('home.how-it-works.step3.title'),
        description: t('home.how-it-works.step3.description'),
        image: step3Image,
        video: step3Video,
      },
    ],
    [t]
  );

  const clearTimer = useCallback(
    () => timer.current && clearInterval(timer.current),
    []
  );

  const setUpTimer = useCallback(() => {
    clearTimer();
    timer.current = window.setInterval(() => {
      setProgressRate((p) => p + 1);
    }, PROGRESS_TIME / 100);
  }, [clearTimer]);

  const getStarted = useCallback(() => {
    navigate('/chat', {
      state: { newCase: true, message: undefined },
    });
  }, [navigate]);

  const changeIndex = useCallback(
    (index: number) => {
      if (index !== activeIndex) {
        setActiveIndex(index);
      }
    },
    [activeIndex]
  );

  const handleNextIndex = useCallback(
    (setIndex?: number) => {
      if (activeIndex === setIndex) return;
      if (isTransitioning) return;
      setIsTransitioning(true);
      clearTimer();
      // Start transition
      const nextIndex = setIndex ?? (activeIndex + 1) % carouselSteps.length;

      // Preload the next video in a hidden element
      nextVideoRef.current.src = carouselSteps[nextIndex].video;
      nextVideoRef.current.load();

      const goNext = () => {
        setActiveIndex(nextIndex);
        setIsTransitioning(false);
      };
      if (screenType === WidthType.Mobile) {
        goNext();
      } else {
        // Allow a brief transition, then swap the videos
        setTimeout(() => {
          goNext();
        }, ANIMATION_DELAY); // Transition duration
      }
    },
    [screenType, clearTimer, carouselSteps, activeIndex, isTransitioning]
  );

  useEffect(() => {
    setUpTimer();
    // we only want to start up the timer on init
    return () => clearInterval(timer.current!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // for mobile, we use the progress to hit 100% instead
  useEffect(() => {
    if (screenType === WidthType.Mobile) {
      if (progressRate === 100 && !isTransitioning) {
        setProgressRate(0);
        handleNextIndex();
      }
    }
  }, [screenType, handleNextIndex, progressRate, isTransitioning]);

  useEffect(() => {
    // when the index changes, we want to set up the timer and progress bar
    setUpTimer();
  }, [activeIndex, setUpTimer]);

  const emailUrl = useMemo(() => {
    return `mailto:${CHAT_EMAIL}?subject=Legal question`;
  }, []);

  useEffect(() => {
    const button = document.getElementById('email-button');
    if (button) {
      button.classList.add(style.emailLink);
      button.onclick = () => {
        window.location.href = emailUrl;
      };
    }
  }, [emailUrl]);

  useEffect(() => {
    // on load we want to preload all the videos
    carouselSteps.forEach((step) => {
      const video = document.createElement('video');
      video.src = step.video;
      video.load();
    });
  }, [carouselSteps]);

  const GetStartedComponent = useMemo(() => {
    return (
      <div className={style.getStartedContainer}>
        <div className={style.getStartedTitle}>
          {t('home.how-it-works.get-started-title')}
        </div>
        <div className={style.getStartedButtonContainer}>
          <button className={style.getStartedButton} onClick={getStarted}>
            <div className={style.getStartedButtonLabel}>
              {t('home.how-it-works.get-started-button')}
            </div>

            <div className={style.iconContainer}>
              <div className={style.getStartedIconContainerHover}>
                <ArrowRightIcon className={style.getStartedIcon} />
              </div>
              <div className={style.getStartedIconContainer}>
                <ArrowRightIcon className={style.getStartedIcon} />
              </div>
            </div>
          </button>
        </div>
      </div>
    );
  }, [t, getStarted]);

  const changeIndexWrapper = useCallback(
    (index: number) => {
      clearTimer();
      setProgressRate(0);
      setVideoLooped(false);
      handleNextIndex(index);
    },
    [clearTimer, handleNextIndex]
  );

  const onVideoEnded = useCallback(() => {
    if (videoLooped) {
      changeIndexWrapper((activeIndex + 1) % carouselSteps.length);
    } else {
      setVideoLooped(true);
      videoRef.current?.play();
    }
  }, [activeIndex, changeIndexWrapper, videoLooped, carouselSteps?.length]);

  const getVideo = useCallback(
    (index: number) => {
      const step = carouselSteps[index];
      return (
        <video
          ref={videoRef}
          className={`${style.video} ${
            isTransitioning ? style.transition : ''
          }`}
          autoPlay
          loop={false}
          muted
          playsInline
          // poster={step.image}
          controls={false}
          src={step.video}
          onEnded={onVideoEnded}
        />
      );
    },
    [carouselSteps, isTransitioning, onVideoEnded]
  );

  return (
    <div>
      {/* we are going to completely seperate mobile using css so its easier to maintain */}

      <CarouselHeader
        carouselSteps={carouselSteps}
        activeIndex={activeIndex}
        changeIndex={changeIndexWrapper}
      />
      <div className={style.container}>
        <div className={style.videoContainer}>
          {getVideo(activeIndex)}
          {/* Preload the next video */}
          <video ref={nextVideoRef} className={style.nextVideo} />
        </div>
        <div className={style.cardsContainer}>
          {carouselSteps.map((step, index) => {
            const isActive = activeIndex === index;
            return (
              <div
                key={index}
                className={`${style.card} ${isActive ? style.isActive : ''}`}
                onClick={() => changeIndexWrapper(index)}
              >
                <div className={style.cardName}>{step.name}</div>
                <step.Icon className={style.cardIcon} />
                <div className={style.cardTitle}>{step.title}</div>
                <div
                  className={style.cardDescription}
                  dangerouslySetInnerHTML={{ __html: step.description }}
                />
                {/* this is the circular spinner used by the desktop view */}
                <div className={style.loadingSpinner}>
                  <PercentageSpinner
                    percentage={progressRate}
                    size={32}
                    noText={true}
                    strokeWidth={3}
                  />
                </div>
              </div>
            );
          })}
        </div>
        {GetStartedComponent}
      </div>

      <MobileStepsCarousel
        activeIndex={activeIndex}
        progressRate={progressRate}
        changeIndex={changeIndexWrapper}
        GetStartedComponent={GetStartedComponent}
        carouselSteps={carouselSteps}
      />
    </div>
  );
};
